// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
import 'channels'

// Load all Stimulus Controllers
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()

const controllers_context = require.context("../javascripts/application/controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(controllers_context))
