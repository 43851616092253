import ApplicationController from "../../application_controller";

export default class extends ApplicationController {
  toggleVisit(e) {
    let row = e.currentTarget
    let rowDetails = this.el('#visit-detail-' + row.dataset.visitId + ' td')

    if(row.classList.contains('open')){
      rowDetails.style.display = 'none'
      row.classList.remove('open')
    }else{
      rowDetails.style.display = 'table-cell'
      row.classList.add('open')
    }

  }
}
