import { Controller } from 'stimulus'

export default class extends Controller {
  connect(){}

  disconnect(){}

  addClass(cssClass, selector='') {
    this.els(selector).forEach((el) => el.classList.add(cssClass))
  }

  // this.animate('#element', 'fadeInUp', '1s').then(() => console.log('Animation over'));
  animate(selector='', animation='fadeOut', duration='1s'){
    const hideAnimations = ['fadeOut']
    const showAnimations = ['fadeIn']
    return new Promise((resolve, reject) => {
      const node = this.el(selector)

      if (['', 'none'].includes(node.style.display)) node.style.display = 'inline-block'

      node.style.setProperty('--animate-duration', duration);
      node.classList.add('animate__animated', `animate__${animation}`);

      function handleAnimationEnd() {
        if(hideAnimations.includes(animation)){ node.style.display = 'none' }
        node.style.removeProperty('--animate-duration')
        node.classList.remove('animate__animated', `animate__${animation}`);
        node.removeEventListener('animationend', handleAnimationEnd);
        resolve();
      }

      node.addEventListener('animationend', handleAnimationEnd);
    });
  }

  el(selector='') { return (selector == '') ? this.element : this.element.querySelector(selector) }

  els(selector='') { return (selector == '') ? [this.element] : this.element.querySelectorAll(selector) }

  focus(selector=''){ this.el(selector).focus() }

  hide(selector=''){ this.el(selector).style.display = 'none' }

  isHidden(selector='') { this.el(selector).offsetParent === null }

  removeClass(cssClass, selector=''){
    this.els(selector).forEach((el) => el.classList.remove(cssClass))
  }


  // OBSERVERS

  onScrollIn(selector, callback){
    this.addIntersectionObserver(selector, 'in', callback)
  }

  onScrollOut(selector, callback){
    this.addIntersectionObserver(selector, 'out', callback)
  }

  addIntersectionObserver(selector, action, callback){
    if(!this.intersectObs) this.intersectObs = []

    const index = this.intersectObs.length

    const io = new IntersectionObserver((entries) => {
      entries.forEach((e) => {
        if(action === 'in' && e.isIntersecting){
          callback(e.target, this)
        }else if(action === 'out' && !e.isIntersecting){
          callback(e.target, this)
        }
      })
    }, { threshold: 0.25 })

    this.intersectObs[index] = { elements: this.els(selector), observer: io }
  }

  observeIntersections(){
    if(this.intersectObs){
      this.intersectObs.forEach(({elements, observer}) => {
        elements.forEach((el) => { observer.observe(el) })
      })
    }
  }

  unobserveIntersections(){
    if(this.intersectObs){
      this.intersectObs.forEach(({elements, observer}) => {
        elements.forEach(el => observer.unobserve(el))
      })
    }
  }
}


/*
      ppp = new Promise((resolve, reject) => {
        Rails.ajax({
          url,
          type: "GET",
          success: response => resolve(response),
          error: (_jqXHR, _textStatus, errorThrown) => reject(errorThrown)
        });
      });

      ppp.then(response => console.log('THEN: ' + response));
      ppp.catch(err => console.log(err));


railsUpdate(url, field, value) {
  if(this.loading){ return }  # TOFIX
  this.loading = true  # TOFIX

  return new Promise((resolve, reject) => {
    const data = new FormData();
    data.append(field, value);

    Rails.ajax({
      url,
      type: "PUT",
      data,
      success: data => {
        resolve(data);
        this.loading = false # TOFIX
      },
      error: (_jqXHR, _textStatus, errorThrown) => {
        reject(errorThrown);
        this.loading = false # TOFIX
      }
    });
  });
}

railsDelete(url) {
  return new Promise((resolve, reject) => {
    Rails.ajax({
      url,
      type: "DELETE",
      success: response => {
        resolve(response);
      },
      error: (_jqXHR, _textStatus, errorThrown) => {
        reject(errorThrown);
      }
    });
  });
}


      */
